import React from "react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Dropdown, SearchInput } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { ProductSearchModel } from "@components/Shared/model/ProductSearch.model";
import { KnowWhatINeedSearchProps } from "./KnowWhatINeed";
import SelectedProduct from "./Shared/SelectedProduct";
import { UOM } from "@ryerson/common.enums";
import { useNotifications } from "@ryerson/frontend.application";
import { Message } from "@ryerson/frontend.application";
import { useApplication } from "@ryerson/frontend.application";
import { Alert } from "@ryerson/frontend.notification";
import EditZipModal from "./Shared/EditZipModal/EditZipModal";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { formatZipCode } from "@ryerson/frontend.common";
import { QuantityUOM } from "@ryerson/frontend.quantity-uom-inputs";
import { DebouncerPlusEvents, UnitOfMeasureType } from "@enums/common.enums";
import { UOMValue } from "@ryerson/common.uom";

const LabelContainer = styled.div`
	padding-bottom: 7px;
`;

const ProductDropDownContainer = styled(Flex)`
	position: relative;
	margin-top: 10px;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}};
`;

const ProductDropDownList = styled.ul`
	position: absolute;
	cursor: pointer;
	margin: 0px;
	padding: 0px 20px;
	width: 100%;
	max-height: 300px;
	list-style: none;
	z-index: 2;
	overflow: auto;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
	${(props: any) => {
		const { bgColor } = props;
		return css`
			background: ${bgColor};
		`;
	}};
`;

const ProductDropDownListItem = styled.li`
	padding: 20px 0px;
	${(props: any) => {
		const { color } = props;
		return css`
			&:not(:last-child) {
				border-bottom: 1px solid ${color};
			}
		`;
	}};
`;

const ProductDetail = styled(Flex)`
	margin-left: 30px;
`;

const ProductTitle = styled(Typography)`
	margin: 0px;
	word-wrap: break-word;
`;

const ImageContainer = styled.img`
	height: 50px;
	width: 50px;
	object-fit: contain;
	${(props: any) => {
		const { bgColor } = props;
		return css`
			background: ${bgColor};
		`;
	}};
`;

const spacingBetweenInputs = css`
	padding-bottom: 14px;
`;

const mobilePadding = css`
	padding-bottom: 20px;
`;

const controlSize = "xl";

const addDefaultSrc = (event: any) => {
	event.target.src = "/NoImageImage.svg";
};

const ErrorContainer = styled(Flex)`
	position: relative;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}};
`;

const ErrorMessage = styled(Typography)`
	position: absolute;
	z-index: 1;
	padding: 20px;
	width: 100%;
	${(props: any) => {
		const { bColor, bgColor } = props;
		return css`
			background: ${bgColor};
			border-bottom: 1px solid ${bColor};
		`;
	}};
`;

const NotificationsContainer = styled.div`
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 99;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
`;

const QuantityUomContainer = styled(Flex)`
	// Container Styling
	width: 100%;
	margin-bottom: 14px;

	& .qty-uom-component-root {
		width: 100%;
	}
`;

const KnowWhatINeedMobile: React.FC<KnowWhatINeedSearchProps> = ({
	testingId,
	searchInputContainerRef,
	searchResultsRef,
	setShowDropdown,
	onChangeText,
	displaySearchTerm,
	showZipModal,
	setShowZipModal,
	searchTerm,
	showDropdown,
	searchResult,
	isError,
	onClickSelectItem,
	showSelectedProduct,
	selectedProduct,
	quantity,
	displayQuantity,
	isQuantityValueUpdated,
	qtyUom,
	qtyUomDropdown,
	onChangeUnitOfMeasure,
	priceUomDropdown,
	priceUom,
	setPriceUom,
	onClickAddToCart,
	onIconClick,
	content,
	setPrice,
	getQueryString,
	leadTimeAndDeliveryDate,
	setLeadTimeAndDeliveryDate,
	canCartViewEdit,
	isValidZipCode,
	stateMessenger,
	isRetrieving,
	setIsRetrieving,
	retrievePricing,
	setRetrievePricing,
	canCallPricing,
	publishMessage,
	setPricingResponseQuantity,
	message,
	setMessage,
}) => {
	const { theme } = useTheme();
	const label = theme.colors.primary.lightGray;
	const { lighterGray, darkGray, white, secondaryBrand } = theme.colors.primary;
	const { selectedShipToAddress, customerPickup, salesPlant } = useEcommerce();
	const { notifications } = useNotifications();
	const getCorrectCase = (word: string | undefined): string => {
		return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : "";
	};

	const [isDebouncing, setIsDebouncing] = React.useState<boolean>(false);

	const hasPermissionRef = React.useRef<boolean>(false);

	React.useEffect(() => {
		let [messageString, value] = message;
		if (messageString === "is-debouncing") {
			setIsDebouncing(value);
		}
	}, [message]);

	React.useEffect(() => {
		// Subscribe to pub-sub  stateMessenger
		stateMessenger?.subscribe((msg: string, value: any, topic: any) =>
			setMessage([msg, value, topic])
		);

		hasPermissionRef.current = canCartViewEdit();
	}, []);

	const quantityInputRef = React.useRef<HTMLInputElement>(null);

	const isAddToCartDisabled =
		!quantityInputRef?.current?.value || isDebouncing || Number(quantity) === 0;

	const {
		zipCode,
		localization: { language },
		user: { isLoggedIn },
	} = useApplication();

	return (
		<>
			<EditZipModal
				testingId={testingId}
				showZipModal={showZipModal}
				setShowZipModal={setShowZipModal}
			/>
			{notifications?.filter((notification: Message) => {
				return notification.type === "add-to-cart";
			}).length > 0 && (
				<NotificationsContainer>
					{notifications
						?.filter((notification: Message) => {
							return notification.type === "add-to-cart";
						})
						.map((notification: Message, index: number) => {
							return (
								<React.Fragment key={index}>{notification.message}</React.Fragment>
							);
						})}
				</NotificationsContainer>
			)}
			{!isValidZipCode && (
				<Flex css={{ marginLeft: "-10px" }}>
					<Alert severity="error">
						<Typography type="tertiary">{content.invalidZipCode}</Typography>
					</Alert>
				</Flex>
			)}
			<Flex>
				<FlexItem grow={1} style={spacingBetweenInputs}>
					<Flex itemRef={searchInputContainerRef} direction="column">
						{/* Search Input and Zip Selection/Pickup Display */}
						<LabelContainer>
							<Flex
								alignItems={customerPickup ? "flex-start" : "center"}
								direction={customerPickup ? "column" : "row"}
								justifyContent="space-between"
							>
								<FlexItem css={{ marginRight: 2 }}>
									<Typography color={label}>{content.lookingFor}</Typography>
								</FlexItem>
								<Flex
									alignItems="flex-start"
									css={{
										paddingTop: customerPickup ? "7px" : "",
									}}
								>
									<Typography color={label} css={{ paddingRight: "5px" }}>
										{customerPickup
											? content.customerPickupLabel
											: content.shippingTo}
									</Typography>
									<FlexItem
										onClick={() => {
											setShowZipModal(true);
										}}
									>
										<Typography underline="true" color={white}>
											{customerPickup &&
											salesPlant &&
											Object.keys(salesPlant).length > 0
												? `${salesPlant?.publicName}, ${salesPlant?.state}`
												: !isLoggedIn
												? `${zipCode}`
												: `${formatZipCode(
														selectedShipToAddress?.zipCode || zipCode
												  )}`}
										</Typography>
									</FlexItem>
								</Flex>
							</Flex>
						</LabelContainer>
						<SearchInput
							value={displaySearchTerm}
							iconPosition="right"
							onChange={(e) => onChangeText(e)}
							size={controlSize}
							icon={displaySearchTerm ? "close" : undefined}
							iconBackgroundColor={displaySearchTerm ? theme.colors.primary.gray : ""}
							onIconClick={onIconClick}
							placeholder={content.search}
							language={language}
							onFocus={() => {
								setShowDropdown(searchResult.length > 0);
							}}
						/>
						{showDropdown && (
							<ProductDropDownContainer {...{ color: darkGray }}>
								<ProductDropDownList {...{ bgColor: white }}>
									{searchResult.map((item: ProductSearchModel, index: number) => (
										<ProductDropDownListItem
											key={index}
											{...{ color: darkGray }}
											onClick={() => onClickSelectItem(item)}
										>
											<Flex>
												<ImageContainer
													onError={(event) => addDefaultSrc(event)}
													src={`/static-assets/images/renderings/${getCorrectCase(
														item.category
													)}${getCorrectCase(item.form)}${
														item.shape !== "N/A"
															? getCorrectCase(item.shape)
															: ""
													}.png`}
													{...{ bgColor: lighterGray }}
												/>
												<ProductDetail direction="column">
													<ProductTitle size="md" color={secondaryBrand}>
														{item?.fastPath}
													</ProductTitle>
													<Typography size="sm">
														{item?.altPartNumber.replace(
															/<br\/>/gi,
															""
														) || item?.materialMaster}
													</Typography>
													<Typography size="sm">
														{item?.description}
													</Typography>
												</ProductDetail>
											</Flex>
										</ProductDropDownListItem>
									))}
								</ProductDropDownList>
							</ProductDropDownContainer>
						)}

						{isError && (
							<ErrorContainer {...{ color: darkGray }}>
								<ErrorMessage {...{ bgColor: white, bColor: darkGray }}>
									{content.noResultFound.replace(
										"%searchString%",
										searchTerm.toString()
									)}
								</ErrorMessage>
							</ErrorContainer>
						)}
					</Flex>
				</FlexItem>
			</Flex>
			{/* Quantity, UOM, and Price UOM Row */}
			<Flex>
				<FlexItem css={{ width: "100%" }}>
					<QuantityUomContainer>
						<QuantityUOM
							quantityInputRef={quantityInputRef}
							stateMessenger={stateMessenger}
							disabled={
								Object.values &&
								Object.values(selectedProduct).every((value) => !value)
							}
							quantityInputWidth={"100%"}
							uomInputWidth={"100%"}
							DropDownLabelAddition={
								<Typography color={label}>{content.uom}</Typography>
							}
							InputLabelAddition={
								<Flex>
									<Typography color={label}>{content.quantity}</Typography>
								</Flex>
							}
							type="tertiary"
							size={"xl"}
							showLabel={false}
							id={"uom"}
							itemData={selectedProduct}
							quantityUomOptions={qtyUomDropdown}
							inputDataTestId={"qty"}
							dropdownDataTestId={"qty-uom"}
							handleChange={(
								quantityValue: number | undefined,
								uomValue: string
							): void => {
								onChangeUnitOfMeasure(
									UnitOfMeasureType.QUANTITY,
									uomValue as UOM,
									quantityValue
								);
							}}
							quantityValue={quantity}
							uomValue={qtyUom as UOMValue}
							isMobile={true}
						/>
					</QuantityUomContainer>
				</FlexItem>
			</Flex>
			{/* Price Row */}
			<Flex css={{ paddingBottom: "20px" }}>
				<FlexItem css={{ flexBasis: "100%" }}>
					<Flex direction="column">
						<LabelContainer>
							<Typography color={label}>{content.priceUom}</Typography>
						</LabelContainer>
						<Dropdown
							id="puom"
							value={priceUom}
							customPadding={30} // Update Input Field Left Padding
							options={priceUomDropdown}
							onFocus={() =>
								publishMessage(
									`to-debounce`,
									DebouncerPlusEvents.focus,
									"know-what-i-need"
								)
							}
							onChange={(value) => {
								publishMessage(
									`to-debounce`,
									DebouncerPlusEvents.change,
									"know-what-i-need"
								);
								onChangeUnitOfMeasure(UnitOfMeasureType.PRICE, value as UOM);
							}}
							size={controlSize}
							disabled={!showSelectedProduct || priceUomDropdown.length === 1}
							language={language}
							dataTestId="price-uom"
						/>
					</Flex>
				</FlexItem>
			</Flex>
			{showSelectedProduct && (
				<SelectedProduct
					quantity={quantity}
					qtyUom={qtyUom as UOM}
					priceUom={priceUom as UOM}
					content={content}
					selectedProduct={selectedProduct}
					setPrice={setPrice}
					getQueryString={getQueryString}
					leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
					setLeadTimeAndDeliveryDate={setLeadTimeAndDeliveryDate}
					canCallPricing={canCallPricing}
					isRetrieving={isRetrieving}
					setIsRetrieving={setIsRetrieving}
					retrievePricing={retrievePricing}
					setRetrievePricing={setRetrievePricing}
					setPricingResponseQuantity={setPricingResponseQuantity}
				/>
			)}
			{canCartViewEdit() && (
				<Flex style={mobilePadding}>
					<FlexItem grow={1}>
						<Button
							label={content.addToCart}
							onClick={() => onClickAddToCart()}
							fullwidth={true}
							disabled={isAddToCartDisabled}
						/>
					</FlexItem>
				</Flex>
			)}
		</>
	);
};

export default KnowWhatINeedMobile;
